<template>
  <div class="time-container">
    <div class="time-display">
      <!-- <transition name="flip"> -->
      <span
        :key="currentTime"
        class="time-text dir-ltr"
      >
        {{ currentTime }}
      </span>
      <!-- </transition> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentTime: new Date().toLocaleTimeString(),
      timer: null,
      flipTimer: null,
    }
  },

  mounted() {
    // Update time every second
    this.timer = setInterval(this.updateTime, 1000)
    // Force a re-render every 10 seconds for the flip effect
    this.flipTimer = setInterval(() => {
      this.currentTime = new Date().toLocaleTimeString()
    }, 5000)
  },

  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.flipTimer)
  },

  methods: {
    updateTime() {
      this.currentTime = new Date().toLocaleTimeString()
    },
  },
}
</script>

<style scoped>
.time-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 30px;
}

.time-display {
  padding: 0.3rem 1rem;
  text-align: center;
  background-color: #3b6196;
  border-radius: 12px;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Flip Animation for Time Text Only */
.flip-enter-active,
.flip-leave-active {
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}

.flip-enter {
  transform: rotateX(90deg);
  opacity: 0;
}

.flip-leave-to {
  transform: rotateX(-90deg);
  opacity: 0;
}

.time-text {
  display: inline-block;
  transform-origin: center bottom;
}
</style>
