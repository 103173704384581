<template>
  <div class="marquee">
    <div class="marquee-content">
      <span
        v-for="(item, index) in texts"
        :key="index"
        class="marquee-item"
      >
        {{ item }}
      </span>
    </div>
    <div
      class="marquee-content"
      aria-hidden="true"
    >
      <span
        v-for="(item, index) in texts"
        :key="'copy-' + index"
        class="marquee-item"
      >
        {{ item }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>
.marquee {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
  height: 30px;
  align-items: center;
  font-family: "k24";
  font-size: 1.25rem;
  mask-image: linear-gradient(
    to right,
    transparent,
    black 20%,
    black 80%,
    transparent
  );
  -webkit-mask-image: linear-gradient(
    to right,
    transparent,
    black 20%,
    black 80%,
    transparent
  );
}

.marquee-content {
  display: flex;
  padding-right: 2rem;
  animation: scroll-right 25s linear infinite;
}

.marquee-item {
  padding: 0 2rem;
}

@keyframes scroll-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes scroll-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
</style>
