<template>
  <div>
    <v-row class="align-center my-0">
      <v-col
        cols="12"
        sm="auto"
        md="2"
        lg="auto"
      >
        <CurrentTime />
      </v-col>
      <v-col
        cols="12"
        sm="9"
        md="10"
        lg="10"
      >
        <Marque
          id="my-marquee"
          :texts="texts"
        />
      </v-col>
    </v-row>

    <v-carousel
      height="700"
      cycle
      class="rounded"
    >
      <v-carousel-item
        v-for="(slide,i) in slides"
        :key="i"
        :src="slide.img"
      />
    </v-carousel>
  </div>
</template>

<script>
import Marque from '@/components/Global/Marque.vue'
import CurrentTime from '@/components/Global/CurrentTime.vue'

export default {
  components: {
    Marque,
    CurrentTime
  },

  data() {
    return {
      img: null,
      slides: [
        { img: '/images/slide1.jpg' },
        { img: '/images/slide2.jpg' },
        { img: '/images/slide3.jpg' },
      ],
      texts: [
        'پێشەنگ لە نوێگەریدا',
        'یەکەمین سیستەمی پارێزەری',
        'پارێزراوی داتا',
        'پێشەنگ لە نوێگەریدا',
        'یەکەمین سیستەمی پارێزەری',
        'پارێزراوی داتا',
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
</style>